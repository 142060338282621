/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode, useMemo, useState } from "react"
import "react-cookie-consent"
import Header from "./header"
import "../css/tiiro.css"
import "../css/font.css"
import "../css/startPage.css"
import TiiroCookieConsent from "../tiiroCookieConsent"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import Footer from "./footer"
import Navbar from "./navbar"
import { createTheme, ThemeProvider } from "@material-ui/core"
import AppContext, { Locale } from "../../common/appContext"

const theme = createTheme({
  typography: {
    fontFamily: `"Ubuntu"`,
  },
  palette: {
    primary: {
      main: "#fd6f23",
    },
    secondary: {
      main: "#52cc9d",
    },
  },
})

type LayoutProps = {
  initialTextColorThemeWhite?: boolean
  initialLogoWhite?: boolean
  footerClassName?: string
  children?: ReactNode
  hideHeader?: boolean
  locale?: Locale
  path?: string
}

const Layout = ({
  initialTextColorThemeWhite,
  initialLogoWhite: logoWhite,
  footerClassName,
  children,
  hideHeader = false,
  locale = Locale.en,
  path,
}: LayoutProps) => {
  const [hideOnScroll, setHideOnScroll] = useState(false)

  const [initialTheme, setInitialTheme] = useState(true)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isTop = currPos.y > -80
      if (isTop !== initialTheme) {
        setInitialTheme(isTop)
      }

      if (isTop) {
        if (hideOnScroll) {
          setHideOnScroll(false)
        }
      } else {
        const isShow = currPos.y < prevPos.y
        if (isShow !== hideOnScroll) {
          setHideOnScroll(isShow)
        }
      }
    },
    [hideOnScroll, initialTheme]
  )

  const strippedPath = useMemo<string | null>(() => {
    if (path) {
      let result = path?.replace("/en", "")

      return result !== "/" ? result.substring(0, result.length - 1) : result
    }

    path?.replace("/en", "") ?? null
  }, [path])

  return (
    <AppContext.Provider
      value={{
        locale,
        enPath: strippedPath !== null ? `/en${strippedPath}` : null,
        svPath: strippedPath !== null ? strippedPath : null,
      }}
    >
      <ThemeProvider theme={theme}>
        {!hideHeader && (
          <Header hideOnScroll={hideOnScroll}>
            <Navbar
              initialTextColorThemeWhite={
                initialTextColorThemeWhite && initialTheme
              }
              initialLogoWhite={logoWhite && initialTheme}
              scrollTheme={!initialTheme}
            />
          </Header>
        )}
        <div>
          <main>{children}</main>
          <Footer className={footerClassName} />
        </div>
        <TiiroCookieConsent />
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default Layout
