import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import "../css/footer.css"
import MailIcon from "@material-ui/icons/Mail"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import InstagramIcon from "@material-ui/icons/Instagram"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import { Divider, Fab } from "@material-ui/core"
import classNames from "classnames"
import AppContext, { Locale } from "../../common/appContext"
import { getClientTranslations, getLink } from "./helperFunctions"

type FooterProps = {
  className?: string
}

const Footer = ({ className }: FooterProps) => {
  const { locale, enPath, svPath } = useContext(AppContext)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <footer className={classNames("p-md-5", className ?? "u-bg-orange")}>
      <div
        data-sal="fade"
        data-sal-delay="400"
        data-sal-easing="ease"
        className="container"
      >
        <div className="row">
          <div className="col-12 col-xl-4 offset-xl-2 pt-5 p-xl-5">
            <ul>
              <li className="pt-3">
                <Link
                  activeClassName="link-active"
                  className="lead"
                  to={getLink("/", locale)}
                >
                  {getClientTranslations("consulting", locale)}
                </Link>
              </li>
              {/* <li className="pt-3">
                <Link
                  activeClassName="link-active"
                  className="lead"
                  to={getLink("/jobs", locale)}
                >
                  {getClientTranslations("jobs", locale)}
                </Link>
              </li> */}
              <li className="pt-3">
                <Link
                  partiallyActive={true}
                  activeClassName="link-active"
                  className="lead"
                  to={getLink("/employers", locale)}
                >
                  {getClientTranslations("employers", locale)}
                </Link>
              </li>
              <li className="py-3 text-nowrap">
                <Link
                  partiallyActive={true}
                  className="lead"
                  activeClassName="link-active"
                  to={getLink("/about", locale)}
                >
                  team cruto
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-xl-4 pt-5 pb-5 p-xl-5">
            <ul>
              <li className="pt-3">
                <p className="lead text-nowrap mb-0">
                  <strong>{getClientTranslations("contact us", locale)}</strong>
                </p>
              </li>
              <li className="pt-3 d-flex align-items-center">
                <MailIcon className="u-c-white" />
                <a
                  className="lead text-nowrap ps-1"
                  href="mailto:hello@cruto.io"
                >
                  hello@cruto.io
                </a>
              </li>
              <li className="pt-3 d-flex align-items-center">
                <InstagramIcon className="u-c-white" />
                <a
                  className="lead text-nowrap ps-1"
                  href="https://www.instagram.com/cruto.io/?utm_medium=copy_link"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
              <li className="pt-3 d-flex align-items-center">
                <LinkedInIcon className="u-c-white" />
                <a
                  className="lead text-nowrap ps-1"
                  href="https://www.linkedin.com/company/cruto/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-md-5 pb-5 pb-md-0">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-end">
            <p className="fw-bold lead u-c-white mb-0">
              © {new Date().getFullYear()} Cruto
            </p>
          </div>
          <div className="col-12 col-md-6 d-none d-md-flex justify-content-center justify-content-md-end align-items-end">
            <p className="u-c-white pe-3 fw-bold mb-0">
              {getClientTranslations("Back to top", locale)}
            </p>
            <Fab className="glass-morphism" onClick={scrollTop}>
              <ArrowUpwardIcon />
            </Fab>
          </div>
        </div>
      </div>

      {enPath && svPath && (
        <div className="container-fluid py-5">
          <Divider />

          <div className="row">
            <div className="col-12">
              <ul className="p-lg-0">
                <li>
                  <p className="lead text-nowrap mb-0 pt-5">
                    <strong>
                      {getClientTranslations("site language", locale)}
                    </strong>
                  </p>
                </li>

                <li className="pt-3">
                  <Link
                    activeClassName="link-active"
                    className="lead"
                    to={svPath}
                  >
                    svenska
                  </Link>
                </li>

                <li className="pt-3">
                  <Link
                    activeClassName="link-active"
                    className="lead"
                    to={enPath}
                  >
                    english
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      )}
    </footer>
  )
}

export default Footer
