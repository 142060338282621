import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import Logo, { LogoSize } from "../logo"
import classNames from "classnames"
import "../css/navbar.css"
import { Helmet } from "react-helmet"
import AppContext, { Locale } from "../../common/appContext"
import { getClientTranslations, getLink } from "./helperFunctions"

type NavbarProps = {
  initialLogoWhite: boolean
  initialTextColorThemeWhite: boolean
  scrollTheme: boolean
}

const Navbar = ({
  initialLogoWhite,
  initialTextColorThemeWhite,
  scrollTheme,
}: NavbarProps) => {
  const { locale } = useContext(AppContext)
  const [active, setActive] = useState<boolean>(false)

  return (
    <>
      <Helmet>
        <html className={classNames({ "no-scroll": active })} />
      </Helmet>
      <nav className={classNames({ "scroll-theme": scrollTheme })}>
        <aside>
          <Link
            style={{ opacity: "1" }}
            to={locale === Locale.sv ? "/" : "/en"}
          >
            <Logo white={initialLogoWhite} size={LogoSize.medium} />
          </Link>
        </aside>
        <ul className={classNames("nav-menu", { active: active })}>
          <li className={"nav-item"}>
            <Link
              className={classNames("fw-bold", {
                "u-c-white u-o-1": initialTextColorThemeWhite && !active,
              })}
              activeClassName="link-active"
              to={getLink("/", locale)}
            >
              {getClientTranslations("consulting", locale)}
            </Link>
          </li>
          {/* <li className={"nav-item"}>
            <Link
              className={classNames("fw-bold", {
                "u-c-white u-o-1": initialTextColorThemeWhite && !active,
              })}
              activeClassName="link-active"
              to={getLink("/jobs", locale)}
            >
              {getClientTranslations("jobs", locale)}
            </Link>
          </li> */}
          <li className={"nav-item"}>
            <Link
              partiallyActive={true}
              className={classNames("fw-bold", {
                "u-c-white u-o-1": initialTextColorThemeWhite && !active,
              })}
              activeClassName="link-active"
              to={getLink("/employers", locale)}
            >
              {getClientTranslations("employers", locale)}
            </Link>
          </li>
          <li className={"nav-item"}>
            <Link
              className={classNames("fw-bold", {
                "u-c-white u-o-1": initialTextColorThemeWhite && !active,
              })}
              activeClassName="link-active"
              to={getLink("/about", locale)}
            >
              team cruto
            </Link>
          </li>
        </ul>
        <div
          onClick={() => setActive(!active)}
          className={classNames("hamburger", { active: active })}
        >
          <span className={"bar"}></span>
          <span className={"bar"}></span>
          <span className={"bar"}></span>
        </div>
      </nav>
    </>
  )
}

export default Navbar
