import React from "react"

export enum Locale {
  en,
  sv,
}

type ContextType = {
  locale: Locale
  enPath: string | null
  svPath: string | null
}

const AppContext = React.createContext<ContextType>({
  locale: Locale.sv,
  enPath: null,
  svPath: null,
})

export default AppContext
