import * as React from "react"
// @ts-ignore
import LogoIcon from "../assets/logo.inline.svg"
// @ts-ignore
import LogoWhiteIcon from "../assets/logo-white.inline.svg"

export enum LogoSize {
  small = "small",
  medium = "medium",
  large = "large",
  xl = "xl",
}
type LogoProps = {
  size: LogoSize
  white?: boolean
}
const Logo: React.FC<LogoProps> = ({ size, white = false }) =>
  !white ? <LogoIcon /> : <LogoWhiteIcon className={`logo-${size}`} />

export default Logo
