/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { Seo } from "../common/types/seo"
import useSiteMetadata from "../hooks/useSiteMetadata"

type SEOProps = {
  title?: string
  subTitle?: string
  meta?: Seo
}

const SEO = ({ title, subTitle, meta }: SEOProps) => {

  const {
    keywords,
    title: siteTitle,
    titleTemplate,
    siteUrl,
    defaultImage,
    twitter,
  } = useSiteMetadata()

  const pageTitle = meta?.metaTitle ?? title
  const pageDescription = meta?.metaDescription ?? subTitle
  const pageKeywords = meta?.focusKeywords ?? keywords
  const ogImage = meta?.ogImage?.url ?? defaultImage

  return (
    //@ts-ignore
    <Helmet
      htmlAttributes={{ lang: "en" }}
      defaultTitle={siteTitle}
      titleTemplate={titleTemplate}
    >
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
      <meta property="image" content={ogImage} />

      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={ogImage} />
      <meta name="og:type" content="website" />

      <meta name="twitter:site" content={`@${twitter}`} />
      <meta name="twitter:title" content={pageTitle ?? siteTitle} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:src" content={ogImage} />
      <meta name="theme-color" content="#fd6f23" />
    </Helmet>
  )
}

export default SEO
