import React, { useContext } from "react"
import CookieConsent from "react-cookie-consent"
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin"
import { getClientTranslations } from "./layout/helperFunctions"
import AppContext from "../common/appContext"

const TiiroCookieConsent = () => {
  const { locale } = useContext(AppContext)
  const cookieConsent = () => {
    localStorage.setItem(GTAG_OPTIN_KEY, true)
    if (typeof window.loadGtag == "function") {
      window.loadGtag()
    }
  }

  return (
    <CookieConsent
      location="bottom"
      buttonText="Godkänn"
      
      style={{
        background: "rgba( 255, 255, 255, 0.15 )",
        backdropFilter: "blur(10px)",
        boxShadow: "0px 0px 8px 0px #0000002b",
      }}
      contentStyle={{
        color: "black",
        opacity: "0.5",
        textShadow: "1px 1px 2px #0000004d",
      }}
      onAccept={cookieConsent}
      extraCookieOptions={{ domain: ".cruto.io" }}
    >
      {getClientTranslations(
        "This website uses cookies that saves information about your visit in order to improve your experience.",
        locale
      )}
      <br />
    </CookieConsent>
  )
}

export default TiiroCookieConsent
