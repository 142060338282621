import { Locale } from "../../common/appContext"

export const getLink = (link: string, locale: Locale): string => {
  return locale === Locale.sv ? link : `/en${link}`
}

export const getClientTranslations = (key: string, locale: Locale): string => {
  if (locale === Locale.en) {
    return key
  }

  switch (key) {
    //   links
    case "consulting":
      return "konsulting"
    case "jobs":
      return "jobb"
    case "employers":
      return "företag"
    case "the app":
      return "appen"
    case "blog":
      return "blogg"
    case "get that job":
      return "hitta nästa jobb"
    //other
    case "site language":
      return "språk"
    case "contact us":
      return "kontakt"
    case "Back to top":
      return "Till toppen"
    case "all":
      return "alla"
    case "Apply":
      return "Ansök"
    case "More ads":
      return "Fler annonser"
    case "Set your skills":
      return "Välj dina kompetenser"
    case "Next":
      return "Nästa"
    case "Back":
      return "Tillbaka"
    case "To ads":
      return "Till annonser"
    case "> 5 years":
      return "> år"
    case "< 5 years":
      return "< år"
    case "< 3 years":
      return "< 3 år"
    case "< 1 year":
      return "< 1 år"
    case "None":
      return "Ingen"
    case "Read more":
      return "Läs mer"
    case "Generate link":
      return "Skapa länk"
    case "Email":
      return "E-post"
    case "Submit application":
      return "Skicka in ansökan"
    case "First name":
      return "Förnamn"
    case "Last name":
      return "Efternamn"
    case "Linkedin profile URL":
      return "Linkedin profil URL"
    case "Available from":
      return "Tillgänglig från"
    case "Min. hourly rate (kr/h)":
      return "Min. timarvode (kr/tim)"
    case "When you submit an application you agree to our":
      return "När du skickar in en ansökan godkänner du vår"
    case "and":
      return "och"
    case "Upload resume here":
      return "Ladda upp cv här"
    case "Files":
      return "Filer"
    case "Thank you for the application!":
      return "Tack för din ansökan!"
    case "By filling in more information, we can match you with an assignment faster.":
      return "Genom att ange mer information kan vi matcha dig med uppdrag snabbare."
    case "Email is required":
      return "E-post krävs"
    case "Email has wrong format":
      return "E-post har felaktigt format"
    case "Your email":
      return "Din e-post"
    case "Personal link copied to clipboard":
      return "Din personliga länk har kopierats"
    case "Copy personal link":
      return "Kopiera personlig länk"
    case "This website uses cookies that saves information about your visit in order to improve your experience.":
      return "Denna webbsida använder cookies som sparar information om dina besök för att förbättra din upplevelse."
    case "View all assignments":
      return "Se alla uppdrag"
    case "Why Cruto":
      return "Varför Cruto"
    default:
      return key
  }
}
