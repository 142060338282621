import React, { ReactNode } from "react"
import classNames from "classnames"

type HeaderProps = {
  children?: ReactNode
  hideOnScroll: boolean
}
const Header = ({ children, hideOnScroll }: HeaderProps) => (
  <header
    className={classNames("w-100", {
      "hide-header": hideOnScroll,
    })}
  >
    {children}
  </header>
)

export default Header
